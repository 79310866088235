import { all, fork } from "redux-saga/effects";
//layout
import LayoutSaga from "./layouts/saga";
//Auth
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";

import ProfileSaga from "./auth/profile/saga";

//mailbox
// import mailSaga from "./mail/saga";

// Pages > Customer
import CustomerSaga from "./customers/saga";

// Pages > Users
import userSaga from "./users/saga";



export default function* rootSaga() {
  yield all([
    //public
    fork(LayoutSaga),
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ProfileSaga),
    // fork(mailSaga),
    // fork(dashboardEcommerceSaga),
    fork(CustomerSaga),
    fork(userSaga),

  ]);
}
