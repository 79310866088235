import { call, put, takeEvery, all, fork } from "redux-saga/effects";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Crypto Redux States
import {
  GET_CUSTOMER_DATA,
  ADD_CUSTOMER_DATA,
  DELETE_CUSTOMER_DATA,
  DELETE_CUSTOMER_DATA_MULTIPLE,
  UPDATE_CUSTOMER_DATA
} from "./actionType";
import {
  customerApiResponseSuccess,
  customerApiResponseError,
  addCustomerDataSuccess,
  addCustomerDataFail,
  updateCustomerDataSuccess,
  updateCustomerDataFail,
  deleteCustomerDataSuccess,
  deleteCustomerDataFail,
  deleteCustomerDataSuccessMultiple,
  deleteCustomerDataFailMultiple
} from "./action";

//Include Both Helper File with needed methods
import {
  getCustomerData as getCustomerDataApi,
  addCustomerData,
  updateCustomerData,
  deleteCustomerData,
  deleteCustomerDataMultiple
} from "../../helpers/backend_helper";

function* getCustomerData() {
  try {
    const response = yield call(getCustomerDataApi);
    yield put(customerApiResponseSuccess(GET_CUSTOMER_DATA, response));
  } catch (error) {
    yield put(customerApiResponseError(GET_CUSTOMER_DATA, error));
  }
}

function* onAddCustomerData({ payload: data }) {
  try {
    const response = yield call(addCustomerData, data);
    yield put(addCustomerDataSuccess(response));
    toast.success("Cliente agregado satisfactoriamente", { autoClose: 3000 });
  } catch (error) {
    yield put(addCustomerDataFail(error));
    toast.error("Error al agregar cliente", { autoClose: 3000 });
  }
}

function* onUpdateCustomerData({ payload: data }) {
  try {
    const response = yield call(updateCustomerData, data);
    yield put(updateCustomerDataSuccess(response));
    toast.success("Cliente modificado satisfactoriamente", { autoClose: 3000 });
  } catch (error) {
    yield put(updateCustomerDataFail(error));
    toast.error("Error al agregar cliente", { autoClose: 3000 });
  }
}

function* onDeleteCustomerData({ payload: data }) {
  try {
    const response = yield call(deleteCustomerData, data);
    yield put(deleteCustomerDataSuccess(response));
    toast.success("Cliente Eliminado Satisfactoriamente", { autoClose: 3000 });
  } catch (error) {
    yield put(deleteCustomerDataFail(error));
    toast.error("Error al eliminar cliente", { autoClose: 3000 });
  }
}
function* onDeleteCustomerDataMultiple({ payload: data }) {
  console.log("data: ", data);
  try {
    const response = yield call(deleteCustomerDataMultiple, data);
    yield put(deleteCustomerDataSuccessMultiple(response));
    toast.success("Customer Data Delete Successfully", { autoClose: 3000 });
  } catch (error) {
    yield put(deleteCustomerDataFailMultiple(error));
    toast.error("Customer Data Delete Failed", { autoClose: 3000 });
  }
}

export function* watchGetCustomerData() {
  yield takeEvery(GET_CUSTOMER_DATA, getCustomerData);
}

export function* watchAddCustomerData() {
  yield takeEvery(ADD_CUSTOMER_DATA, onAddCustomerData);
}

export function* watchUpdateCustomerData() {
  yield takeEvery(UPDATE_CUSTOMER_DATA, onUpdateCustomerData);
}

export function* watchDeleteCustomerData() {
  yield takeEvery(DELETE_CUSTOMER_DATA, onDeleteCustomerData);
}
export function* watchDeleteCustomerDataMultiple() {
  yield takeEvery(DELETE_CUSTOMER_DATA_MULTIPLE, onDeleteCustomerDataMultiple);
}

function* customerSaga() {
  yield all([
    fork(watchGetCustomerData),
    fork(watchAddCustomerData),
    fork(watchUpdateCustomerData),
    fork(watchDeleteCustomerData),
    fork(watchDeleteCustomerDataMultiple)
  ]);
}

export default customerSaga;
