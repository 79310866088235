import React from "react";
import { Redirect } from "react-router-dom";

//Side Menu
import Dashboard from "../pages/Dashboard";
import Customers from "../pages/Customer";
import Users from "../pages/User";

//login
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";

// User Profile
import UserProfile from "../pages/Authentication/user-profile";


const authProtectedRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/customers", component: Customers },
  { path: "/users", component: Users },
  //User Profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />
  }
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },

];

export { authProtectedRoutes, publicRoutes };
