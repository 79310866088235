import { combineReducers } from "redux";

// Front
import Layout from "./layouts/reducer";

// Authentication
import Login from "./auth/login/reducer";
import Account from "./auth/register/reducer";
// import ForgetPassword from "./auth/forgetpwd/reducer";
import Profile from "./auth/profile/reducer";

// //Form advanced
// import changeNumber from "./formAdvanced/reducer";

//Mailbox
// import Mail from "./mail/reducer";

// // Dashboard Ecommerce
// import DashboardEcommerce from "./dashboardEcommerce/reducer";

// Pages > Customer
import Customers from "./customers/reducer";

// Pages > Users
import Users from "./users/reducer";



const rootReducer = combineReducers({
  // public
  Layout,
  Login,
  Account,
  // ForgetPassword,
  Profile,
  // changeNumber,
  // DashboardEcommerce,
  Customers,
  Users,

});

export default rootReducer;
